.typing-indicator {
    background-color: #E6E7ED;
    will-change: transform;
    width: auto;
    border-radius: 50px;
    padding: 5px;
    display: table;
    margin: 0 auto;
    position: relative;
    -webkit-animation: 2s bulge infinite ease-out;
            animation: 2s bulge infinite ease-out;
  }
  .typing-indicator::before, .typing-indicator::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: -2px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #E6E7ED;
  }
  .typing-indicator::after {
    height: 5px;
    width: 5px;
    left: -5px;
    bottom: -5px;
  }
  .typing-indicator span {
    height: 5px;
    width: 5px;
    float: left;
    margin: 0 1px;
    background-color: #9E9EA1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
  }
  .typing-indicator span:nth-of-type(1) {
    -webkit-animation: 1s blink infinite 0.3333s;
            animation: 1s blink infinite 0.3333s;
  }
  .typing-indicator span:nth-of-type(2) {
    -webkit-animation: 1s blink infinite 0.6666s;
            animation: 1s blink infinite 0.6666s;
  }
  .typing-indicator span:nth-of-type(3) {
    -webkit-animation: 1s blink infinite 0.9999s;
            animation: 1s blink infinite 0.9999s;
  }
  
  @-webkit-keyframes blink {
    50% {
      opacity: 1;
    }
  }
  
  @keyframes blink {
    50% {
      opacity: 1;
    }
  }
  @-webkit-keyframes bulge {
    50% {
      transform: scale(1.05);
    }
  }
  @keyframes bulge {
    50% {
      transform: scale(1.05);
    }
  }
