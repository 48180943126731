.App {
  font-family: 'Montserrat, sans-serif' !important;
}

/* .navbar {
  box-shadow: 0px 100px 3px -100px rgb(255, 255, 255);
}

.nav-item {
  padding: 5rem;
  padding-left: 8rem;
}

.nav-link {
  text-decoration: none;
  color: white;
  font-size: 1.25rem;
}

.card {
  background-color: #212529!important;
  -webkit-box-shadow: 2px 5px 11px 2px #000000; 
  box-shadow: 2px 5px 11px 2px #000000;
  text-align: justify;
}

.card-img, .card-img-top {
  max-width: 60px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */ 
