.st0{
    fill:none;
    stroke:#ffffff;
    stroke-miterlimit:10;
    stroke-dasharray: 1920;
    stroke-dashoffset: 1920;
    -webkit-animation: draw1 3s linear forwards;
    animation: draw1 3s linear forwards;
    }

      
@-webkit-keyframes draw1{
    to {stroke-dashoffset: 0;}
}
@keyframes draw1{
    to {stroke-dashoffset: 0;}
}
@-webkit-keyframes draw2{
    to {stroke-dashoffset: 0;}
}
@keyframes draw2{
    to {stroke-dashoffset: 0;}
}